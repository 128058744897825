<template>
  <div class="ui labeled icon left inline vertical sidebar menu">
    <a class="item" href="/">
      <i class="home icon"></i>
      {{titulo}}
    </a>
    <a v-for="menu in menus" :key="menu.id" class="item" v-bind:href="menu.href" v-bind:class="{ active: menu.activa }" data-turbolinks="false" @click="acceso(menu.href)" >
      <i v-bind:class="menu.icon"></i>
      {{menu.opcion}}
    </a>
  </div>
</template>

<script>
const Api = require("../packs/api");
// import { EventBus } from "../packs/eventbus.js";

export default {
  props: ["activo", "titulo", "rol"],
  data: function() {
    return {
      message: "Hello Vue!",
      menus: [
        {
          id: 1,
          opcion: "Facturas",
          icon: "shopping cart icon",
          activa: this.menuactivo("Facturas"),
          href: "/facturasm"
        },
        {
          id: 2,
          opcion: "Albaranes",
          icon: "dolly icon",
          activa: this.menuactivo("Albaranes"),
          href: "/albaranesm"
        },
        {
          id: 3,
          opcion: "Presupuestos",
          icon: "clipboard list icon",
          activa: this.menuactivo("Presupuestos"),
          href: "/presupuestosm"
        },
        {
          id: 4,
          opcion: "Clientes",
          icon: "users icon",
          activa: this.menuactivo("Clientes"),
          href: "/clientesm"
        },
        {
          id: 5,
          opcion: "Entregas",
          icon: "money bill alternate outline icon",
          activa: this.menuactivo("Entregas"),
          href: "/entregasm"
        },
        // {
        //   id: 6,
        //   opcion: "Caja",
        //   icon: "euro sign icon",
        //   activa: this.menuactivo("Caja"),
        //   href: "/cajasm"
        // }
      ]
    };
  },
  created() {
  },
  mounted() {
    var _opc = {
          id: 6,
          opcion: "Caja",
          icon: "euro sign icon",
          activa: this.menuactivo("Caja"),
          href: "/cajasm"
        };

    var _opc2 = {
          id: 7,
          opcion: "Articulos",
          icon: "barcode icon",
          activa: this.menuactivo("Articulo"),
          href: "/articulosm"
        };
        
    var _opc3 = {
          id: 8,
          opcion: "Familias",
          icon: "child icon",
          activa: this.menuactivo("Familia"),
          href: "/familiasm"
        };

    if (this.rol == "admin" || this.rol == "caja") {
      this.menus.push(_opc);
    }

    this.menus.push(_opc2);
    this.menus.push(_opc3);

  },
  methods: {
    acceso: function(p) {
      // console.log('fite', p);
      // EventBus.$emit("enqueformularioestoy", 44);
      $(".ui.labeled.icon.sidebar").sidebar("toggle");
      location.href = p;
    },
    menuactivo: function(o) {
      return o == this.activo;
    }
  }
};
</script>

<style scoped>
.item.bottom {
  top: 100% !important;
  position: ‘stiky’ !important;
}

p {
  font-size: 2em;
  text-align: center;
}
</style>
